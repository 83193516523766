<template>
  <div class="px-8 lg:px-0 w-full max-w-screen">
    <div class="flex flex-col content-center items-center">
      <Avatar
        size="large"
        :src="`https://storage.googleapis.com/cavea-avatar-service/twitch/${getUserInfo.username}/avatar.png`"
        class="mb-2"
      />

      <input
        v-model="creatorPage.heading"
        type="text"
        placeholder="Title"
        class="w-full text-2xl font-bold text-center text-grey-default bg-transparent focus:outline-none"
      />

      <input
        v-model="creatorPage.subheading"
        type="text"
        placeholder="Subtitle"
        class="w-full text-xl font-bold text-center text-grey-default bg-transparent focus:outline-none"
      />

      <div v-if="creatorPage && creatorPage.icons && creatorPage.icons.length" class="flex gap-6 my-4">
        <div v-for="(icon, iconIndex) of creatorPage.icons" :key="iconIndex">
          <a :href="icon.url" rel="noopener noreferrer" target="_blank" class="flex gap-1 content-center">
            <Icon :icon="icon.icon" width="24px" height="24px" />
          </a>

          <Button kind="link" class="mt-1" @clicked="openEditModal(iconIndex, 'icon')"> Edit </Button>
        </div>
      </div>

      <div
        v-for="(showcaseItem, showcaseItemIndex) of creatorPage.body"
        :key="showcaseItemIndex"
        class="flex w-full max-w-lg h-full text-center place-center"
        :class="showcaseItem.type === 'text' ? 'pt-4 pb-2' : 'py-2'"
      >
        <div
          v-if="creatorPage.body.length > 1"
          class="absolute"
          style="left: -2.5rem; top: 50%; transform: translate(0, -50%)"
        >
          <Button
            v-if="showcaseItemIndex > 0"
            kind="link"
            class="transform rotate-180"
            @clicked="moveItem('up', showcaseItemIndex)"
          >
            <Icon icon="arrow" width="24px" height="24px" />
          </Button>

          <Button
            v-if="showcaseItemIndex < creatorPage.body.length - 1"
            kind="link"
            @clicked="moveItem('down', showcaseItemIndex)"
          >
            <Icon icon="arrow" width="24px" height="24px" />
          </Button>
        </div>

        <a
          v-if="showcaseItem.type === 'link'"
          :href="showcaseItem.url || '#'"
          rel="noopener noreferrer"
          target="_blank"
          class="
            p-2
            w-full
            h-full
            text-xl
            bg-blue-default
            hover:bg-transparent
            border-2 border-solid border-blue-default
          "
        >
          {{ showcaseItem.text }}
        </a>

        <Title
          v-else-if="showcaseItem.type === 'text'"
          :size="showcaseItem.size || 'medium'"
          :title="showcaseItem.text"
          class="p-2 w-full h-full"
        />

        <!-- Edit item -->
        <div class="absolute" style="right: -2.5rem; top: 50%; transform: translate(0, -50%)">
          <Button kind="link" @clicked="openEditModal(showcaseItemIndex, 'link')">
            <Icon icon="settings" width="24px" height="24px" />
          </Button>
        </div>
      </div>
    </div>

    <div class="flex gap-4 m-auto mt-4 max-w-lg">
      <Button label="Add new item" kind="link" @clicked="openEditModal" />
      <Button label="Copy link" kind="secondary" class="ml-auto" @clicked="copyLink" />
      <Button label="Save" @clicked="saveCreatorPage" />
    </div>

    <div
      v-if="editModal"
      style="background-color: rgba(0, 0, 0, 0.4); z-index: 1058"
      class="flex fixed top-0 left-0 place-content-center place-items-center w-screen h-screen rounded"
      @click="resetEditModal"
    >
      <div class="p-8 w-full max-w-xl h-auto" @click.stop>
        <Tile class="p-8 w-full h-auto" style="z-index: 1059">
          <div class="flex">
            <Title :title="typeof editItemIndex === 'number' ? 'Edit item' : 'Add new item'" size="large" />

            <button class="ml-auto" @click="resetEditModal">
              <Icon icon="close" height="20px" />
            </button>
          </div>

          <!-- Item type -->
          <div class="flex items-center mt-4">
            <p class="font-medium text-blue-lighter">Type</p>

            <Dropdown
              :border="true"
              :items="getItemTypes"
              class="ml-auto capitalize"
              :current-item="editItem.type"
              :index="getItemTypes.indexOf(editItem.type)"
              @change="changeItemType"
            />
          </div>

          <!-- Icon -->
          <div v-if="['icon'].indexOf(editItem.type) !== -1" class="flex items-center mt-4">
            <p class="font-medium text-blue-lighter">Icon</p>

            <Dropdown
              :border="true"
              :items="getIconTypes"
              class="ml-auto capitalize"
              :current-item="editItem.icon"
              :index="getIconTypes.indexOf(editItem.icon)"
              @change="setIconType"
            />
          </div>

          <!-- Size -->
          <div v-if="['text'].indexOf(editItem.type) !== -1" class="flex items-center mt-4">
            <p class="font-medium text-blue-lighter">Size</p>

            <Dropdown
              :border="true"
              :items="getTextSizes"
              class="ml-auto capitalize"
              :current-item="editItem.size"
              :index="getTextSizes.indexOf(editItem.size)"
              @change="setTextSize"
            />
          </div>

          <!-- Text -->

          <InputText
            v-if="['link', 'text'].indexOf(editItem.type) !== -1"
            label="Text"
            placeholder="Text"
            class="mt-4"
            type="text"
            :required="true"
            :value="editItem.text"
            @onChange="setItemText"
          />

          <!-- URL -->
          <InputText
            v-if="['link', 'icon'].indexOf(editItem.type) !== -1"
            label="Link"
            placeholder="Link"
            class="mt-4"
            type="url"
            :required="true"
            :value="editItem.url"
            @onChange="setUrl"
          />

          <!-- Toggle disabled state -->
          <!-- <InputToggle :checked="!showcaseItem.disabled" :index="showcaseItemIndex" @toggle="toggleItem" /> -->

          <!-- Save -->
          <div class="flex gap-4 mt-4 mr-auto ml-auto">
            <Button id="button-streamer-save-creator-page" class="text-white-default" @clicked="saveItem">
              <Icon icon="save" height="20px" class="mr-2 fill-white-default" color="white" />

              <span class="text-white-default"> Save </span>
            </Button>

            <!-- Delete -->
            <Button
              v-if="editItemIndex !== -1 && typeof editItemIndex === 'number'"
              class="px-6 h-10 text-white-default bg-signal-red rounded"
              kind="link"
              @clicked="removeItem"
            >
              <Icon icon="trashcan" height="20px" class="mr-2 fill-white-default" color="white" />

              <span class="text-white-default"> Delete </span>
            </Button>
          </div>
        </Tile>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { Title, Avatar, Button, Tile, Icon } from "cavea-design-system";
import log from "@/lib/log";

export default {
  name: "StreamerCreatorPageBuilder",

  components: {
    Title,
    Avatar,
    Button,
    Icon,
    Tile,
    InputText: () => import("@/components/inputs/InputText"),
    Dropdown: () => import("@/components/Dropdown"),
  },

  data() {
    return {
      currentTab: 0,
      editModal: false,
      editItem: null,
      editItemIndex: null,
      editItemType: null,
      creatorPage: {
        contentCreatorId: null,
        shortCode: null,
        heading: "",
        subheading: "",
        image: null,
        icons: [],
        body: [],
      },
    };
  },

  computed: {
    ...mapGetters(["getUserInfo"]),

    /**
     * @returns {{ label: string, type: string, id: string}[]}
     */
    getIconTypes() {
      return [
        { label: "facebook", type: "icon", id: "facebook" },
        { label: "instagram", type: "icon", id: "instagram" },
        { label: "tiktok", type: "icon", id: "tiktok" },
        { label: "trovo", type: "icon", id: "trovo" },
        { label: "twitch", type: "icon", id: "twitch" },
        { label: "twitter", type: "icon", id: "twitter" },
        { label: "youtube", type: "icon", id: "youtube" },
      ];
    },

    /**
     * @returns {{ label: string, id: string}[]}
     */
    getItemTypes() {
      return [
        { label: "link", id: "link" },
        { label: "text", id: "text" },
        { label: "icon", id: "icon" },
      ];
    },

    /**
     * @returns {{ label: string, id: string}[]}
     */
    getTextSizes() {
      return [
        { label: "large", id: "large" },
        { label: "medium", id: "medium" },
        { label: "small", id: "small" },
      ];
    },
  },

  watch: {
    getUserInfo: {
      handler() {
        this.fetchExistingPage();
      },
    },
  },

  created() {
    if (this.getUserInfo) {
      this.fetchExistingPage();
    }
  },

  metaInfo: {
    title: "Creator Page Builder",
  },

  methods: {
    /**
     * @summary check if user has an existing page
     */
    async fetchExistingPage() {
      log({ fn: "fetchExistingPage" });

      const URL = `${process.env.VUE_APP_API_URL}/creator-page/${this.getUserInfo.username}`;

      const result = await axios
        .get(URL)
        .then((res) => res?.data?.creatorPage)
        .catch((error) => {
          console.error({ msg: "fetchExistingPage error", error });
          return null;
        });

      if (result) {
        this.creatorPage = result;
      } else {
        this.creatorPage = {
          ...this.creatorPage,
          heading: this.getUserInfo?.username,
          shortCode: this.getUserInfo?.username,
          contentCreatorId: this.getUserInfo?._id,
        };
      }

      log({ fn: "fetchExistingPage result", result, creatorPage: this.creatorPage });
    },

    /**
     * @param {string} dir move directio
     * @param {number} index item index
     */
    moveItem(dir, index) {
      log({ fn: "moveItem", dir, index });
      const pageBody = JSON.parse(JSON.stringify(this.creatorPage.body));
      log(pageBody[index].text);

      if (dir === "up") {
        log("up");

        const item = pageBody[index];

        pageBody[index] = pageBody[index - 1];
        pageBody[index - 1] = item;
      } else {
        log("down");

        const item = pageBody[index];
        pageBody[index] = pageBody[index + 1];
        pageBody[index + 1] = item;
      }

      log(pageBody[index].text);

      this.creatorPage.body = pageBody;
    },

    /**
     * @summary opens the edit modal
     * params are sent if editing existing item
     * @param {number | null} index existing item index
     * @param {string | null} type existing item type
     */
    openEditModal(index = null, type = null) {
      log({ fn: "openEditModal", index });

      if (index !== null && type) {
        this.editItemIndex = index;
        this.editItemType = type;

        if (type === "icon") {
          this.editItem = this.creatorPage.icons[index];
        } else if (type === "link") {
          this.editItem = this.creatorPage.body[index];
        }
      } else {
        this.editItem = {
          text: "",
          link: "",
          type: "link",
          disabled: false,
        };
      }

      this.editModal = true;
    },

    /**
     * @summary reset edit modal to original state
     */
    resetEditModal() {
      this.editModal = false;
      this.editItemIndex = null;
      this.editItem = null;
    },

    /**
     * @param {string} url
     * @param {number | null} editItemIndex optional
     */
    setUrl(url, editItemIndex = null) {
      log({ fn: "setUrl", url, editItemIndex });

      if (editItemIndex !== null) {
        this.creatorPage.body[editItemIndex].url = url;
      } else {
        this.editItem.url = url;
      }
    },

    /**
     * @param {string} e
     * @param {number | null} editItemIndex optional
     */
    setItemText(e, editItemIndex = null) {
      log({ fn: "setItemText", e, editItemIndex });

      if (editItemIndex !== null) {
        this.creatorPage.body[editItemIndex].text = e;
      } else {
        this.editItem.text = e;
      }
    },

    /**
     * @param {{ index: number | null, prop: any, item: { id: string | null, index: number | null, label: string | null }}} e
     */
    setTextSize(e) {
      log({ fn: "setTextSize", e });

      if (e?.item?.label) {
        this.editItem.size = e?.item?.label?.toLowerCase();
      }
    },

    /**
     * @param {{ index: number | null, prop: any, item: { id: string | null, index: number | null, label: string | null }}} e
     */
    changeItemType(e) {
      log({ fn: "changeItemType", e });

      if (e?.item?.label === "link") {
        this.editItem = {
          type: "link",
          text: this.editItem?.text || "",
          url: this.editItem?.url || "",
          disabled: false,
        };
      } else if (e?.item?.label === "icon") {
        this.editItem = {
          type: "icon",
          icon: this.getIconTypes[0].label,
          url: this.editItem?.url || "",
          disabled: false,
        };
      } else if (e?.item?.label === "text") {
        this.editItem = {
          type: "text",
          text: this.editItem?.text || "",
          size: "medium",
          disabled: false,
        };
      }
    },

    /**
     * @param {{ index: number | null, prop: any, item: { id: string | null, index: number | null, label: string | null }}} e
     */
    setIconType(e) {
      log({ fn: "setIconType", e });

      if (e?.item?.label) {
        this.editItem.icon = e?.item?.label?.toLowerCase();
      }
    },

    /**
     * @summary switches disabled state of item
     * the bool has to be inverted since the toggle
     * component returns true when the item isn't disabled
     * @param {boolean} state
     * @param {number} index
     */
    toggleItem(state, index) {
      log({ fn: "toggleItem", state, index });

      if (typeof index === "number" && index !== -1) {
        this.creatorPage.body[index].disabled = !state;
      }
    },

    /**
     * @summary saves or appends the item to the given array
     */
    saveItem() {
      log({ fn: "saveItem" });

      if (
        this.editItem?.url &&
        this.editItem?.url?.indexOf("https://") === -1 &&
        this.editItem?.url?.indexOf("http://") === -1
      ) {
        this.editItem.url = `https://${this.editItem?.url}`;
      }

      // Handle switching between icons and link/text
      if (this.editItemIndex !== null) {
        if (this.editItem?.type === "icon" && this.editItem.type !== this.editItemType) {
          this.creatorPage.body.splice(this.editItemIndex, 1);
          this.creatorPage.icons.push(this.editItem);
        } else if (this.editItem?.type !== "icon" && this.editItemType === "icon") {
          this.creatorPage.icons.splice(this.editItemIndex, 1);
          this.creatorPage.body.push(this.editItem);
        } else if (this.editItem?.type === "icon") {
          this.creatorPage.icons[this.editItemIndex] = this.editItem;
        } else {
          this.creatorPage.body[this.editItemIndex] = this.editItem;
        }
      } else if (this.editItem.type === "icon") {
        this.creatorPage.icons.push(this.editItem);
      } else {
        this.creatorPage.body.push(this.editItem);
      }

      this.resetEditModal();
    },

    async removeItem() {
      if (this.editItemIndex !== null && this.editItemType) {
        const confirmation = await this.$swal({
          title: `Are you sure you want to remove this item?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#c13859",
          confirmButtonText: `Yes`,
        });

        if (confirmation?.isConfirmed) {
          if (this.editItemType === "icon") {
            this.creatorPage.icons.splice(this.editItemIndex, 1);
          } else {
            this.creatorPage.body.splice(this.editItemIndex, 1);
          }

          this.resetEditModal();
        }
      }
    },

    /**
     * @param {boolean} skipPopup default false
     */
    async saveCreatorPage(skipPopup = false) {
      log({ fn: "saveCreatorPage" });

      const URL = `${process.env.VUE_APP_API_URL}/creator-page`;

      await axios
        .post(URL, this.creatorPage)
        .then((res) => {
          log({ msg: "Creator page has been saved successfully", res });
          this.creatorPage = res?.data?.result;

          if (!skipPopup) {
            this.$swal({
              icon: "success",
              title: `Creator page has been saved successfully`,
              showConfirmButton: false,
              timer: 1000,
            });
          }
        })
        .catch((error) => {
          console.error({ msg: "Something went wrong saving creatorPage", error });

          this.$swal({
            icon: "error",
            title: "Something went wrong, please try again. ",
            timer: 800,
            showConfirmButton: false,
          });
        });
    },

    async copyLink() {
      log({ fn: "copyLink" });

      const text = `https://netwrk.gg/l/${this.creatorPage.shortCode}`;

      try {
        navigator.clipboard.writeText(text);

        this.$swal({
          icon: "success",
          title: `Copied link succesfully`,
          showConfirmButton: false,
          timer: 800,
        });
      } catch (error) {
        console.error("Error saving to clipboard", error);

        this.$swal({
          icon: "error",
          title: "Something went wrong, please try again",
          timer: 800,
          showConfirmButton: false,
        });
      }
    },
  },
};
</script>
